import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { Language } from 'src/types/common/Language';

export const LANGUAGE: C.Codec<unknown, Json, Language> = C.literal(
  'de-CH',
  'en-US',
  'fr-FR',
  'hu-HU',
  'es-ES',
  'sv-SE',
  'zh-CN',
);
