import './UserLanguage.scss';
import { RadioButton } from '@carbon/react';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { LANGUAGE_LIST } from 'src/modules/common/constants/language';
import { GlobeIcon } from 'src/modules/common/icons/GlobeIcon';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { Language } from 'src/types/common/Language';
import { TranslationKey } from 'src/types/common/TranslationKey';

export const UserLanguage = memo(() => {
  const intl = useIntl();

  const language = useSelector(getLanguage);

  const switchLanguage = useAction(CONFIG_LANGUAGE_SWITCH.request);

  return (
    <CustomMenu
      className="bp-language"
      flipped={true}
      iconDescription={intl.formatMessage({ id: 'common/header/language' })}
      tooltipPosition="bottom"
      renderIcon={() => (
        <div className="bp-language__icon">
          <GlobeIcon/>
        </div>
      )}
    >
      {LANGUAGE_LIST.map((languageOption) => (
        <CustomMenuItem
          key={languageOption}
          onClick={() => switchLanguage(languageOption)}
          itemText={(
            <RadioButton
              labelText={intl.formatMessage({ id: LANGUAGE_KEYS[languageOption] })}
              value={languageOption}
              checked={language === languageOption}
              id={languageOption}
            />
          )}
          hasDivider={true}
        />
      ))}
    </CustomMenu>
  );
});

const LANGUAGE_KEYS: ReadonlyRecord<Language, TranslationKey> = {
  'en-US': 'header/user/language/en-US',
  'de-CH': 'header/user/language/de-CH',
  'fr-FR': 'header/user/language/fr-FR',
  'hu-HU': 'header/user/language/hu-HU',
  'es-ES': 'header/user/language/es-ES',
  'sv-SE': 'header/user/language/sv-SE',
  'zh-CN': 'header/user/language/zh-CN',
};
