import { Language } from 'src/types/common/Language';

export const LANGUAGE_LIST: ReadonlyArray<Language> = [
  'en-US',
  'de-CH',
  'fr-FR',
  'hu-HU',
  'es-ES',
  'sv-SE',
  'zh-CN',
];
