import { LANGUAGE_LIST } from 'src/modules/common/constants/language';
import { Language } from 'src/types/common/Language';

export function getPreferredLanguage(): Language {
  const navigatorLanguageLocale = navigator.language.toLowerCase();

  return LANGUAGE_LIST.reduce((prev, languageWithLocale) => {
    const supportedLanguage = languageWithLocale.substring(0, 2);
    const navigatorLanguage = navigatorLanguageLocale.substring(0, 2);

    if (supportedLanguage === navigatorLanguage || supportedLanguage === navigatorLanguage) {
      return languageWithLocale;
    }

    return prev;
  }, 'en-US');
}
