import './BasedataFormView.scss';
import { AreaUnit, BuildingType, COUNTRY_CODES, CountryCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { constNull } from 'fp-ts/function';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BasedataFormData } from 'src/modules/basedata/types/BasedataFormData';
import { AreaUnitView } from 'src/modules/common/components/AreaUnitView';
import { BottomNavigationHelper } from 'src/modules/common/components/BottomNavigationHelper';
import { TileCard } from 'src/modules/common/components/TileCard';
import { MAX_TRILLION } from 'src/modules/common/constants/decimal';
import { INTL_LOCALE_MAP } from 'src/modules/common/constants/locale';
import { BUILDING_TYPE_OPTIONS, UNIT_VERSION_OPTIONS } from 'src/modules/common/constants/options';
import { AssetField } from 'src/modules/form/components/AssetField';
import { AutocompleteField } from 'src/modules/form/components/AutocompleteField';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { TextField } from 'src/modules/form/components/TextField';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly projectId: string;
  readonly form: FormController<BasedataFormData, FormError>;
  readonly language: Language;
};

export const BasedataFormView = ({ projectId, form, language }: Props): React.ReactElement => {
  const intl = useIntl();
  const collator = useMemo(() => new Intl.Collator(INTL_LOCALE_MAP[language]), [language]);

  const projectHvacUrl = `/project/${encodeURIComponent(projectId)}/hvac`;

  const countryCodesFilteredByLanguage = useMemo(
    () => COUNTRY_CODES.map((code) => ({
      label: intl.formatMessage({ id: `common/country/name/${code}` }),
      value: code,
    }))
      .sort((optionA, optionB) => collator.compare(optionA.label, optionB.label)),
    [intl, collator],
  );

  return (
    <div className="bp-basedata-form-view">
      <div className="bp-basedata-form-view__content">
        <div className="bp-basedata-form-view__row">
          <TileCard title={<FormattedMessage id="basedata/projectTitle/label"/>}>
            <TextField
              size="md"
              labelText={intl.formatMessage({ id: 'basedata/projectTitle/title' })}
              field={form.field.at('title')}
              autoComplete="off"
              maxLength={40}
              placeholder={intl.formatMessage({ id: 'basedata/projectTitle/placeholder' })}
            />
          </TileCard>
        </div>

        <div className="bp-basedata-form-view__row">
          <TileCard title={<FormattedMessage id="basedata/projectGoals"/>}>
            <TextAreaField
              labelText=""
              field={form.field.at('projectGoals')}
              rows={10}
              maxLength={280}
              showHint={true}
            />
          </TileCard>

          <TileCard title={<FormattedMessage id="basedata/coverImage"/>}>
            <AssetField field={form.field.at('cover')}/>
          </TileCard>
        </div>

        <div className="bp-basedata-form-view__row">
          <TileCard title={<FormattedMessage id="basedata/building"/>}>
            <div className="bp-basedata-form-view__card-row">
              <DropdownField
                size="md"
                wrapperClass="bp-basedata-form-view__field-wrapper bp-basedata-form-view__type-field"
                options={BUILDING_TYPE_OPTIONS}
                field={form.field.at('building').at('type').transform(ensureNonNull())}
                titleText={intl.formatMessage({ id: 'basedata/building/type' })}
                itemToElement={renderBuildingTypeOption}
                renderSelectedItem={renderBuildingTypeOption}
              />

              <DecimalField
                size="md"
                wrapperClass="bp-basedata-form-view__field-wrapper bp-basedata-form-view__size-field"
                field={form.field.at('building').at('size')}
                labelText={intl.formatMessage({ id: 'basedata/building/size' })}
                autoComplete="off"
                maxValue={MAX_TRILLION}
              />

              <DropdownField
                size="md"
                wrapperClass="bp-basedata-form-view__field-wrapper bp-basedata-form-view__unit-field"
                options={UNIT_VERSION_OPTIONS}
                field={form.field.at('building').at('unit').transform(ensureNonNull())}
                titleText={intl.formatMessage({ id: 'basedata/building/unit' })}
                itemToElement={renderUnitOptions}
                renderSelectedItem={renderUnitOptions}
              />
            </div>

            <div className="bp-basedata-form-view__card-row">
              <TextField
                size="md"
                wrapperClass="bp-basedata-form-view__street-field"
                labelText={intl.formatMessage({ id: 'basedata/building/address/street' })}
                field={form.field.at('building').at('address').at('street')}
                maxLength={30}
                autoComplete="off"
                renderHint={constNull}
              />
            </div>

            <div className="bp-basedata-form-view__card-row">
              <TextField
                size="md"
                wrapperClass="bp-basedata-form-view__city-field"
                labelText={intl.formatMessage({ id: 'basedata/building/address/city' })}
                field={form.field.at('building').at('address').at('city')}
                maxLength={20}
                renderHint={constNull}
              />
            </div>

            <div className="bp-basedata-form-view__card-row">
              <AutocompleteField
                size="md"
                wrapperClass="bp-basedata-form-view__field-wrapper bp-basedata-form-view__country-field"
                titleText={intl.formatMessage({ id: 'basedata/building/address/country' })}
                field={form.field.at('building').at('address').at('country').transform(ensureNonNull())}
                options={countryCodesFilteredByLanguage}
                itemToElement={renderCountryOption}
                direction="top"
              />

              <TextField
                size="md"
                wrapperClass="bp-basedata-form-view__zip-field"
                field={form.field.at('building').at('address').at('zip')}
                labelText={intl.formatMessage({ id: 'basedata/building/address/zipCode' })}
                autoComplete="off"
                maxLength={10}
                renderHint={constNull}
              />
            </div>
          </TileCard>

          <TileCard title={<FormattedMessage id="basedata/participants"/>}>
            <TextField
              size="md"
              labelText={intl.formatMessage({ id: 'basedata/participants/author' })}
              field={form.field.at('participants').at('author')}
              maxLength={50}
              renderHint={constNull}
            />

            <TextField
              size="md"
              labelText={intl.formatMessage({ id: 'basedata/participants/propertyManager' })}
              field={form.field.at('participants').at('propertyManager')}
              maxLength={50}
              renderHint={constNull}
            />

            <TextField
              size="md"
              labelText={intl.formatMessage({ id: 'basedata/participants/consutlingEngineer' })}
              field={form.field.at('participants').at('consutlingEngineer')}
              maxLength={50}
              renderHint={constNull}
            />

            <TextField
              size="md"
              labelText={intl.formatMessage({ id: 'basedata/participants/productManager' })}
              field={form.field.at('participants').at('productManager')}
              maxLength={50}
              renderHint={constNull}
            />
          </TileCard>
        </div>

        <BottomNavigationHelper
          routeForthText={intl.formatMessage({ id: 'common/bottomNavigation/hvac' })}
          routeForthPath={projectHvacUrl}
        />
      </div>
    </div>
  );
};

function renderBuildingTypeOption(option: BuildingType): React.ReactElement {
  return <FormattedMessage id={`common/buildingType/${option}`}/>;
}

function renderUnitOptions(option: AreaUnit): React.ReactElement {
  return <AreaUnitView unit={option}/>;
}

function renderCountryOption(option: CountryCode): React.ReactElement {
  return <FormattedMessage id={`common/country/name/${option}`}/>;
}
