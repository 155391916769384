import { Language } from 'src/types/common/Language';

export const DATE_FORMAT: Record<Language, string> = {
  'de-CH': 'd.m.Y',
  'en-US': 'd.m.Y',
  'fr-FR': 'd.m.Y',
  'hu-HU': 'd.m.Y',
  'es-ES': 'd.m.Y',
  'sv-SE': 'd.m.Y',
  'zh-CN': 'd.m.Y',
};
